<template>
  <div class="all">
    <top-menu-bar
      v-if="!system.isPhone"
      :curScroll="curScroll"
      @curIndex="curIndex"
    />
    <bar-wap
      v-if="system.isPhone"
      class="wap_bar_menu"
      :curScroll="curScroll"
      @curIndex="curIndex"
    />
    <div v-if="!system.isPhone">
      <img
        class="dailitupian"
        :src="
          $t('languages') == 'EN'
            ? require('@/assets/img/apply/' + topImg + 'en.jpg') +
              `?t=${new Date().getTime()}`
            : require('@/assets/img/apply/' + topImg + '.jpg') +
              `?t=${new Date().getTime()}`
        "
      />
    </div>
    <div :class="system.isPhone ? 'wap_form wapfont24' : 'form font24'">
      <div class="center">
        <img
          class="ok_icon"
          :src="
            require('@/assets/img/apply/basic _ circle_check_outline@2x.png')
          "
        />
        <div class="text_info">{{ namewaitReview }}</div>
        <el-button class="back_home " type="primary" @click="backHome">{{
          $t("nameBackHome")
        }}</el-button>
      </div>
    </div>
    <bottom-det v-if="!system.isPhone" />
  </div>
</template>
<script>
import TopMenuBar from "@/views/publicComponents/TopMenuBar";
import BottomDet from "@/views/publicComponents/BottomDet";
// import WapBottom from "@/views/publicComponents/WapBottom";
import BarWap from "@/views/publicComponents/BarWap";
export default {
  components: {
    TopMenuBar,
    BottomDet,
    // WapBottom,
    BarWap
  },
  data() {
    return {
      curScroll: 0,
      namewaitReview:
        this.$route.query.status === "tijiaochenggong"
          ? this.$t("nameSubSucess")
          : this.$t("namewaitReview"),
      topImg: this.$route.query.status || "daishenhe",
      labelPosition: "right",
      formLabelAlign: {
        name: "",
        region: "",
        type: ""
      }
    };
  },
  mounted() {},
  methods: {
    curIndex(index) {
      this.curScroll = index;
    },
    backHome() {
      this.$router.replace({
        path: "/"
      });
    }
  }
};
</script>
<style lang="less" scoped>
.all {
  // min-width: 750px;
}

.form {
  position: relative;
  width: calc(100vw * 127.2 / 192);
  // min-width: 750px;
  background-color: white;
  height: auto;
  margin: auto;
  margin-top: -50px;
  padding-top: 44px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  padding-bottom: 36px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  .center {
    display: flex;

    flex-direction: column;
  }
  .back_home {
    width: 272px;
    height: 53px;
    opacity: 1;
    background: #1977fb;
    border-radius: 67px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  }
  .ok_icon {
    height: 150px;
    width: 150px;
    margin: auto;
    margin-top: 56px;
    margin-bottom: 24px;
  }
  .text_info {
    margin-bottom: 164px;
  }
}
.dailitupian {
  width: 100%;
}
// 移动端样式
.wap_form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: calc(100vw * 9 / 37.5);
  .back_home {
    width: 272px;
    height: 53px;
    opacity: 1;
    background: #1977fb;
    border-radius: 67px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  }
  .ok_icon {
    height: 150px;
    width: 150px;
    margin: auto;
    margin-top: 56px;
    margin-bottom: 24px;
  }
  .text_info {
    margin-bottom: 164px;
  }
}
.wap_header {
  position: relative;
  margin-top: calc(100vw * 13 / 37.5);
  height: calc(100vw * 5 / 37.5);
  margin-top: calc(100vw * 8 / 37.5);
  font-family: "FF1";
  color: rgba(25, 119, 251, 1);
  margin-right: 20px;
  margin-left: 20px;
  text-align: left;
}
</style>
